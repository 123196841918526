import React from 'react';

import Image from 'components/Image';
import Container from 'components/layout/Container';
import ResponsiveBox from 'components/layout/ResponsiveBox';
import Section from 'components/layout/Section';
import { mobile, tablet } from 'utils/media';
import { PortableText } from 'utils/sanity';

import { Background, Title, Tree, TreeContainer } from './styles';
import { CopyTreeProps } from './types';

const borderImageRatio = { [mobile]: 0.29, [tablet]: 0.1465 };

export default function CopyTree({
  colorScheme,
  borderImage,
  title,
  sections,
}: CopyTreeProps) {
  return (
    <Background colorScheme={colorScheme}>
      {borderImage ? (
        <ResponsiveBox aspectRatio={borderImageRatio}>
          <Image
            sizes="100vw"
            alt=""
            aspectRatio={borderImageRatio}
            src={borderImage}
          />
        </ResponsiveBox>
      ) : null}
      <Section>
        <Container inset>
          {title ? (
            <Title>
              <PortableText blocks={title} />
            </Title>
          ) : null}
          {sections?.length ? (
            <TreeContainer>
              <Tree>
                {sections.map((section) => (
                  <div key={section._key}>
                    <PortableText blocks={section.body} />
                  </div>
                ))}
              </Tree>
            </TreeContainer>
          ) : null}
        </Container>
      </Section>
    </Background>
  );
}
