import styled from 'styled-components';

import { CarouselItem, CarouselRow } from 'components/layout/Carousel';
import {
  ArrowButton,
  CarouselRowInner,
} from 'components/layout/Carousel/styles';
import SiteLink from 'components/Link';
import { Heading4 } from 'components/text';
import { desktop, largeDesktop, tablet } from 'utils/media';
import theme from 'utils/theme';

import { TWithColorScheme } from './types';

export const CarouselItemStyled = styled(CarouselItem)`
  min-width: 240px;
  width: 240px;

  ${tablet} {
    min-width: 210px;
    width: 210px;
  }

  ${desktop} {
    min-width: 290px;
    width: 290px;
  }

  ${largeDesktop} {
    min-width: 390px;
    width: 390px;
  }
`;

export const CarouselRowStyled = styled(CarouselRow)`
  ${ArrowButton} {
    ${tablet} {
      top: ${280 / 2 + 'px'};
    }

    ${desktop} {
      top: ${367 / 2 + 'px'};
    }

    ${largeDesktop} {
      top: ${520 / 2 + 'px'};
    }
  }

  // TODO: Remove when all other carousels are brought in line with this one
  ${CarouselRowInner} {
    ${desktop} {
      padding: 0;
      -ms-overflow-style: none; // IE and Edge
      scrollbar-width: none; // Firefox

      // Chrome and Safari
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const SiteLinkStyled = styled(SiteLink)`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  height: 100%;
`;

export const TileBody = styled.div<TWithColorScheme>`
  width: 100%;
  color: ${({ colorScheme }) =>
    colorScheme in theme.colors ? theme.colors[colorScheme] : 'inherit'};
`;

export const TileTitle = styled(Heading4)<TWithColorScheme>`
  margin-top: 30px;
  margin-bottom: 0;
  color: ${({ colorScheme }) =>
    colorScheme in theme.colors ? theme.colors[colorScheme] : 'inherit'};

  + * {
    margin-top: 5px;
  }
`;
