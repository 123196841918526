import { chunk } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Chevron } from 'components/icons';
import Image from 'components/Image';
import ResponsiveBox from 'components/layout/ResponsiveBox';

import {
  Wrapper,
  MobileRow,
  TabletRow,
  Row,
  Block,
  MobileBlockImage,
  TabletBlockImage,
  BlockIndicator,
  Spacer,
  DotsRow,
  Dot,
  PageRow,
  PageArrow,
  RowOverflow,
} from './styles';
import { SocialCarouselProps } from './types';

function MobileRowContent({ images }: SocialCarouselProps) {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const innerRef = useRef<HTMLDivElement>(null);

  const scrollListener = useCallback(() => {
    if (innerRef.current) {
      setActiveItem(
        Math.round(innerRef.current.scrollLeft / innerRef.current.offsetWidth),
      );
    }
  }, []);

  useEffect(() => {
    scrollListener();
  }, [scrollListener]);

  return (
    <>
      <RowOverflow>
        <Row ref={innerRef} onScroll={scrollListener}>
          <Spacer />
          {images.map((image, index) => (
            <Block key={image._key}>
              <BlockIndicator>
                {index + 1}/{images.length}
              </BlockIndicator>
              <MobileBlockImage>
                <ResponsiveBox aspectRatio={1}>
                  <Image
                    src={image.asset}
                    aspectRatio={1}
                    alt={image.altText || ''}
                    sizes="calc(100vw - 40px)"
                  />
                </ResponsiveBox>
              </MobileBlockImage>
            </Block>
          ))}
          <Spacer />
        </Row>
      </RowOverflow>
      <DotsRow>
        {images.map((_image, index) => (
          <Dot key={index} active={index === activeItem} />
        ))}
      </DotsRow>
    </>
  );
}

function TabletRowContent({ images }: SocialCarouselProps) {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const innerRef = useRef<HTMLDivElement>(null);

  const blocks = chunk(images, 2);

  const scrollListener = useCallback(() => {
    if (innerRef.current) {
      const offset =
        innerRef.current.scrollLeft /
        (innerRef.current.scrollWidth - innerRef.current.offsetWidth);

      setActiveItem(Math.round((blocks.length - 1) * offset));
    }
  }, [blocks.length]);

  const jumpPrevious = useCallback(() => {
    if (typeof activeItem === 'number' && activeItem >= 0) {
      const nextEl = innerRef.current?.childNodes[activeItem];
      if (nextEl) {
        (nextEl as HTMLDivElement).scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      }
    }
  }, [activeItem]);

  const jumpNext = useCallback(() => {
    if (typeof activeItem === 'number' && activeItem < blocks.length) {
      const nextEl = innerRef.current?.childNodes[activeItem + 2];
      if (nextEl) {
        (nextEl as HTMLDivElement).scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      }
    }
  }, [activeItem, blocks.length]);

  useEffect(() => {
    scrollListener();
  }, [scrollListener]);

  return (
    <>
      <RowOverflow>
        <PageRow>
          <PageArrow
            $disabled={typeof activeItem !== 'number' || activeItem <= 0}
            type="button"
            onClick={jumpPrevious}
          >
            <Chevron />
          </PageArrow>
          <PageArrow
            $disabled={
              typeof activeItem !== 'number' || activeItem >= blocks.length - 1
            }
            type="button"
            onClick={jumpNext}
          >
            <Chevron />
          </PageArrow>
        </PageRow>
        <Row ref={innerRef} onScroll={scrollListener}>
          <Spacer />
          {blocks.map((block, index) => (
            <Block key={index}>
              <BlockIndicator>
                {index + 1}/{blocks.length}
              </BlockIndicator>
              {block.map((image) => (
                <TabletBlockImage key={image._key}>
                  <ResponsiveBox aspectRatio={1}>
                    <Image
                      src={image.asset}
                      aspectRatio={1}
                      alt={image.altText || ''}
                      sizes="500px"
                    />
                  </ResponsiveBox>
                </TabletBlockImage>
              ))}
            </Block>
          ))}
          <Spacer />
        </Row>
      </RowOverflow>
      <DotsRow>
        {blocks.map((_block, index) => (
          <Dot key={index} active={index === activeItem} />
        ))}
      </DotsRow>
    </>
  );
}

export default function SocialCarousel({ images }: SocialCarouselProps) {
  return (
    <Wrapper>
      <MobileRow>
        <MobileRowContent images={images} />
      </MobileRow>
      <TabletRow>
        <TabletRowContent images={images} />
      </TabletRow>
    </Wrapper>
  );
}
