import React from 'react';
import { useInView } from 'react-intersection-observer';

import ResponsiveBox from 'components/layout/ResponsiveBox';

import { VideoPlayerProps } from './types';

export default function VideoPlayer({
  video,
  autoplay = false,
}: VideoPlayerProps) {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!video) {
    return null;
  }

  return (
    <ResponsiveBox aspectRatio={9 / 16} ref={ref}>
      {inView && (
        <iframe
          title="Video"
          src={`https://player.cloudinary.com/embed/?public_id=${encodeURIComponent(
            video.public_id,
          )}&cloud_name=sproutl&player%5Bposter_options%5D%5Btransformation%5D%5Bstart_offset%5D=0&player%5Bshow_logo%5D=false&player%5Bfluid%5D=true&player%5Bcontrols%5D=true&player%5Bfont_face%5D=Acme&player%5Bcolors%5D%5Baccent%5D=%23a77aff&source%5Bsource_types%5D%5B0%5D=hls${
            autoplay ? '&player%5Bautoplay%5D=true&player%5Bmuted%5D=true' : ''
          }`}
          width="1920"
          height="1080"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
          }}
          allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
          allowFullScreen
          frameBorder="0"
        />
      )}
    </ResponsiveBox>
  );
}
