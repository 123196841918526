import styled from 'styled-components';

import ResponsiveBox from 'components/layout/ResponsiveBox';
import { Heading1 } from 'components/text';
import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface FixedHeaderProps {
  aspectRatio: any;
}

export const FixedHeader = styled.div<FixedHeaderProps>({
  backgroundColor: theme.colors.offWhite,
  textAlign: 'center',
  padding: '40px 0',
});

export const HeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

interface StyledTextProps {
  colorScheme: Sproutl.ColorScheme;
}

export const StyledHeading = styled(Heading1)<StyledTextProps>((props) => ({
  position: 'relative',
  zIndex: 1,
  padding: '0 20px',
  maxWidth: 850,
  color:
    props.colorScheme in theme.colors
      ? theme.colors[props.colorScheme]
      : undefined,
}));

export const StyledSubtitle = styled.div<StyledTextProps>((props) => ({
  color:
    props.colorScheme in theme.colors
      ? theme.colors[props.colorScheme]
      : undefined,

  position: 'relative',
  maxWidth: 320,
  zIndex: 1,
  p: {
    marginTop: 0,
  },

  [desktop]: {
    maxWidth: 700,
  },
}));

export const StyledDeepLink = styled.a({
  color: theme.colors.white,
  fontSize: 16,
});

export const ButtonWrapper = styled.div({
  position: 'relative',
  zIndex: 1,
  [desktop]: {
    marginTop: 30,
  },
});

export const StyledResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.offWhite,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: theme.colors.lightGreen,
});

export const Underlay = styled.div`
  background: ${theme.colors.iconUnderlay};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
