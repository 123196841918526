import styled from 'styled-components';

import ResponsiveBox from 'components/layout/ResponsiveBox';
import { desktop, tablet } from 'utils/media';
import theme from 'utils/theme';

export const StyledResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.white,
  borderRadius: 40,
  [desktop]: {
    borderRadius: 80,
  },
});

export const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const Label = styled.div({
  marginTop: 16,
  fontWeight: 'bold',
  fontSize: 14,
  lineHeight: 1.5,
  color: theme.colors.black,
  [tablet]: {
    fontSize: 16,
  },
});

export const Price = styled.div({
  color: theme.colors.darkGreen,
});

interface TabLinkProps {
  active: boolean;
}

export const TabLink = styled.button<TabLinkProps>`
  ${(props) => props.active && 'display: none;'};
  appearance: none;
  border: 0;
  padding: 0;
  font-size: 16px;
  margin: 0 20px 20px 0;
  background: transparent;
  font-weight: bold;
  color: ${theme.colors.black};
  opacity: ${(props) => (props.active ? 1 : 0.4)};
  cursor: pointer;

  ${tablet} {
    display: unset;
  }

  &:focus,
  &:hover {
    outline: none;
    opacity: ${(props) => (props.active ? 1 : 0.5)};
  }
`;

export const TabsRow = styled.div({
  marginBottom: -20,
});
