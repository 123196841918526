import styled from 'styled-components';

import TitleLink from 'components/TitleLink';
import { tablet } from 'utils/media';
import theme from 'utils/theme';

const TitleRow = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0 20px;
  color: ${theme.colors.purple};
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding: 0 20px;

  ${tablet} {
    padding: 0 40px;

    ${TitleLink} {
      margin-left: 30px;
    }
  }
`;

export default TitleRow;
