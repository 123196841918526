import Link from 'next/link';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

import Image from 'components/Image';
import { CarouselRow, CarouselItem } from 'components/layout/Carousel';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import SiteLink from 'components/Link';
import { Heading2 } from 'components/text';
import TitleLink from 'components/TitleLink';
import { ITitleLink } from 'components/TitleLink/types';
import TitleRow from 'components/TitleRow';
import { getProductUrl } from 'utils/commercetools/product';
import formatPrice from 'utils/formatPrice';
import { mobile } from 'utils/media';

import {
  HeaderContainer,
  TabsRow,
  TabLink,
  StyledResponsiveBox,
  Label,
  Price,
} from './styles';

export interface ProductCarouselTab {
  _key: string;
  title: ReactNode;
  titleLink?: ITitleLink;
  products?: Sproutl.ProductListing[];
}

export interface ProductCarouselProps {
  tabs?: ProductCarouselTab[];
  extendedWidth?: boolean;
}

interface StyledHeadingProps {
  hasTabs: boolean;
}

const StyledHeading = styled(Heading2)<StyledHeadingProps>((props) =>
  props.hasTabs
    ? {
        [mobile]: {
          fontSize: 32,
        },
      }
    : {},
);

export default function ProductCarousel({
  tabs = [],
  extendedWidth = true,
}: ProductCarouselProps) {
  const [selectedTab, selectTab] = useState(0);

  if (!tabs.length) {
    return null;
  }

  const { text, url } = tabs[selectedTab]?.titleLink || {};

  return (
    <Section bg="normal">
      <Container noPadding noGutter={extendedWidth}>
        <HeaderContainer>
          <TitleRow>
            <StyledHeading hasTabs={tabs.length > 1} inline>
              {tabs[selectedTab].title}
            </StyledHeading>
            {text && url && (
              <Link href={url} passHref>
                <TitleLink href={url}>{text}</TitleLink>
              </Link>
            )}
          </TitleRow>
          {tabs.length > 1 && (
            <TabsRow>
              {tabs.map((tab, index) => (
                <TabLink
                  key={index}
                  onClick={() => selectTab(index)}
                  active={index === selectedTab}
                >
                  {tab.title}
                </TabLink>
              ))}
            </TabsRow>
          )}
        </HeaderContainer>
        <CarouselRow>
          {tabs[selectedTab].products?.map((product) => {
            if (!product || !product.sku) {
              return null;
            }

            return (
              <CarouselItem
                size="large"
                key={product.sku}
                data-testid="productCarouselItem"
              >
                <SiteLink href={getProductUrl(product)} title={product.name}>
                  <StyledResponsiveBox aspectRatio={1}>
                    {product.photo ? (
                      <Image
                        loader="cloudinary"
                        src={product.photo}
                        aspectRatio={1}
                        sizes={`${mobile} 150px, 290px`}
                        alt={product.name}
                      />
                    ) : null}
                  </StyledResponsiveBox>
                  <Label>
                    <div>{product.name}</div>
                    {product.price ? (
                      <Price>{formatPrice(product.price)}</Price>
                    ) : null}
                  </Label>
                </SiteLink>
              </CarouselItem>
            );
          })}
        </CarouselRow>
      </Container>
    </Section>
  );
}
