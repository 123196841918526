import React from 'react';

import Image from 'components/Image';
import ResponsiveBox from 'components/layout/ResponsiveBox';
import { mobile, tablet } from 'utils/media';

import { BreakpointWrapper } from './style';
import { ResponsiveImageBlockProps } from './types';

export default function ResponsiveImageBlock({
  desktop: desktopImage,
  mobile: mobileImage,
}: ResponsiveImageBlockProps) {
  const aspectRatio = {
    [tablet]: 1,
    [mobile]: mobileImage ? 4 / 1 : 1,
  };

  return (
    <ResponsiveBox aspectRatio={aspectRatio}>
      <BreakpointWrapper media={tablet}>
        <Image
          src={desktopImage}
          aspectRatio={aspectRatio[tablet]}
          alt=""
          sizes="100vw"
        />
      </BreakpointWrapper>

      <BreakpointWrapper media={mobile}>
        <Image
          src={mobileImage ? mobileImage : desktopImage}
          aspectRatio={aspectRatio[mobile]}
          alt=""
          sizes="100vw"
        />
      </BreakpointWrapper>
    </ResponsiveBox>
  );
}
