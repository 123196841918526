import styled, { css } from 'styled-components';

import ResponsiveBox from 'components/layout/ResponsiveBox';
import MaskedIcon from 'components/MaskedIcon';
import { Heading4, Heading2 } from 'components/text';
import { desktop, mobile, tablet, desktopMinusOne } from 'utils/media';
import theme from 'utils/theme';

import { isOverlay } from './helpers';
import {
  WrapperProps,
  BannerProps,
  BannerAlignment,
  BannerContentWidth,
} from './types';

export const Banner = styled.div<BannerProps>`
  position: relative;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  background-image: ${(props) =>
    props.bannerUrl ? `url(${props.bannerUrl})` : 'none'};
  flex: 50%;
  order: ${(props) => (props.alignment === 'left' ? 1 : 0)};
  ${(props) =>
    isOverlay(props.alignment)
      ? css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        `
      : css`
          ${mobile} {
            position: relative;
            height: 0;
            padding-top: 64%;
          }
        `}
`;

export const TwoColWrapper = styled.div<WrapperProps>`
  position: relative;
  text-align: left;
  display: block;
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor in theme.colors
      ? theme.colors[props.backgroundColor]
      : '#fff'};
  color: ${(props) =>
    props.textColor ? theme.colors[props.textColor] : 'inherit'};
  z-index: 1;

  ::before {
    display: block;
    content: '';
    width: 1;
    margin-left: -1;
    float: left;
    height: 0;
    padding-top: 85%;

    ${tablet} {
      padding-top: 33.333%;
    }
  }

  ::after {
    display: table;
    clear: both;
    content: '';
  }

  ${tablet} {
    display: flex;
  }
`;

interface TextSectionProps {
  alignment?: BannerAlignment;
  darkenOverlay?: boolean;
  contentWidth?: BannerContentWidth;
}

export const TextSection = styled.div<TextSectionProps>`
  display: flex;
  position: relative;
  flex: 50%;
  align-items: flex-end;
  padding: 40px 20px;
  z-index: 1;

  ${tablet} {
    padding: 40px;
    align-items: center;
    justify-content: flex-start;
  }

  ${desktopMinusOne} {
    padding: ${(props) =>
      props.alignment === 'centerOverlay' ? '40px' : '40px 80px 40px 40px'};
    flex: ${(props) => props.contentWidth || '50%'};
  }

  ${(props) =>
    isOverlay(props.alignment) &&
    css`
      background: ${(props: TextSectionProps) =>
        props.darkenOverlay ? theme.colors.iconUnderlay : 'none'};

      ${(props: TextSectionProps) =>
        props.alignment === 'rightOverlay' &&
        css`
          ${tablet} {
            justify-content: flex-end;
          }
        `}

      > div {
        ${tablet} {
          width: 50%;

          ${(props: TextSectionProps) =>
            props.alignment === 'centerOverlay' &&
            css`
              margin: 0 auto;
              text-align: center;
            `}
        }
      }
    `}
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
`;

export const LabelWrapper = styled.div`
  margin-bottom: 24px;
`;

export const StyledHeading4 = styled(Heading4)`
  line-height: 1;
`;

export const StyledMaskedIcon = styled(MaskedIcon)`
  margin-right: 5px;
`;

interface OverlayResponsiveBoxProps {
  textColor?: Sproutl.ColorScheme;
}

export const OverlayResponsiveBox = styled(
  ResponsiveBox,
)<OverlayResponsiveBoxProps>`
  display: flex;
  color: ${(props) =>
    props.textColor ? theme.colors[props.textColor] : 'inherit'};
`;

export const DesktopLargerHeading2 = styled(Heading2)`
  ${desktop} {
    font-size: 80px;
    line-height: 86px;
  }
`;
