import React from 'react';

import Loader from 'components/global/Loader';
import Container from 'components/layout/Container';
import { PortableText } from 'utils/sanity';
import theme from 'utils/theme';

import {
  TitleRow,
  SocialSection,
  SocialHeading2,
  CarouselContainer,
  CuratorCarouselDropSpot,
} from './styles';
import { SocialBlockProps } from './types';

export default function SocialBlock({
  divId,
  title,
  description,
  label,
  colorScheme,
}: SocialBlockProps) {
  return (
    <SocialSection hexCode={label?.hexCode}>
      <Container noPadding noGutter>
        {title || description ? (
          <TitleRow>
            {title ? (
              <SocialHeading2 textColor={colorScheme?.text} inline>
                {title}
              </SocialHeading2>
            ) : null}
            <PortableText blocks={description} />
          </TitleRow>
        ) : null}
        <CarouselContainer>
          <Loader
            zIndex={theme.zIndexes.low}
            background={theme.colors.modalBackgroundLight}
            heightOverride={'calc(100% - 36px)'}
          />
          <CuratorCarouselDropSpot zIndex={theme.zIndexes.mid} id={divId} />
        </CarouselContainer>
      </Container>
    </SocialSection>
  );
}
