import styled from 'styled-components';

import { desktop } from 'utils/media';

export const StyleInnerContainer = styled.div`
  width: 100%;
  padding: 56px 0;
  max-width: 950px;
  text-align: center;

  ${desktop} {
    text-align: left;
  }
`;

export const TextContainer = styled.div`
  font-size: 16px;

  ${desktop} {
    font-size: 24px;
  }
`;
