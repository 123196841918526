import Link from 'next/link';
import React from 'react';

import BackgroundVideo from 'components/BackgroundVideo';
import Button from 'components/Button';
import Image from 'components/Image';
import Container from 'components/layout/Container';
import ResponsiveBox from 'components/layout/ResponsiveBox';
import Section from 'components/layout/Section';
import { Heading2 } from 'components/text';
import TitleLink from 'components/TitleLink';
import TitleRow from 'components/TitleRow';
import { desktop, largeDesktop, mobile, tablet } from 'utils/media';
import { PortableText } from 'utils/sanity';

import * as St from './styles';
import { IContentCarouselProps } from './types';

export type { IContentCarouselProps };

export default function ContentCarousel({
  tiles,
  title,
  titleLink = {},
}: IContentCarouselProps) {
  const { text, url } = titleLink;
  return (
    <Section bg="light">
      <Container noGutter noPadding>
        <TitleRow>
          <Heading2 inline>{title}</Heading2>
          {text && url && (
            <Link href={url} passHref>
              <TitleLink href={url}>{text}</TitleLink>
            </Link>
          )}
        </TitleRow>

        <St.CarouselRowStyled arrowLayout="overlay" inline>
          {tiles?.map(({ body, colorScheme, cta, image, title, video }) => {
            return (
              <St.CarouselItemStyled
                data-testid="contentCarouselItem"
                key={title}
                size="xxlarge"
              >
                <St.SiteLinkStyled href={cta.url} title={title}>
                  <St.TileBody colorScheme={colorScheme.text}>
                    <ResponsiveBox aspectRatio={4 / 3} rounded>
                      {video ? (
                        <BackgroundVideo
                          video={video}
                          breakpoints={{
                            [mobile]: { width: 240, height: 320 },
                            [tablet]: { width: 210, height: 280 },
                            [desktop]: { width: 290, height: 367 },
                            [largeDesktop]: { width: 390, height: 520 },
                          }}
                        />
                      ) : image ? (
                        <Image
                          aspectRatio={4 / 3}
                          src={image.media}
                          sizes={`${mobile} 240px, ${tablet} 210px, ${desktop} 290px, ${largeDesktop} 390px`}
                          alt={image.altText}
                        />
                      ) : null}
                    </ResponsiveBox>

                    <St.TileTitle as="h3" colorScheme={colorScheme.text}>
                      {title}
                    </St.TileTitle>

                    {body ? (
                      <PortableText blocks={body} color={colorScheme.text} />
                    ) : null}
                  </St.TileBody>

                  {cta.title ? (
                    <Button
                      as="span"
                      colorScheme={
                        colorScheme.button !== 'primary'
                          ? colorScheme.button
                          : undefined
                      }
                      variant={
                        colorScheme.button === 'primary'
                          ? 'primary'
                          : 'secondary'
                      }
                    >
                      {cta.title}
                    </Button>
                  ) : null}
                </St.SiteLinkStyled>
              </St.CarouselItemStyled>
            );
          })}
        </St.CarouselRowStyled>
      </Container>
    </Section>
  );
}
