import React from 'react';

import ProductItem, { ProductSkeleton } from 'components/ProductItem';
import { mobile, tablet } from 'utils/media';

import { Grid } from './styles';
import { IProductsData } from './types';

const defaultColumns = {
  [mobile]: 2,
  [tablet]: 3,
};

const ProductGrid = ({
  products = [],
  skeleton,
  columns = defaultColumns,
  skeletonOutOfStockProducts = false,
}: IProductsData) => (
  <Grid columns={columns}>
    {products.map((product: any, index) =>
      skeleton || (!product.isOnStock && skeletonOutOfStockProducts) ? (
        <ProductSkeleton key={`${product.sku}-${index}`} />
      ) : (
        <ProductItem
          key={`${product.sku}-${index}`}
          product={product}
          data-testid="productGridItem"
        />
      ),
    )}
  </Grid>
);

export default ProductGrid;
