import styled from 'styled-components';

import { desktop, mobile, tablet } from 'utils/media';
import theme from 'utils/theme';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
  font-size: 0;
  --block-width: min(calc(100vw), 1000px);

  ${mobile} {
    &:first-child {
      margin-top: -10px;
    }
  }
`;

export const RowOverflow = styled.div`
  overflow: hidden;
`;

export const Row = styled.div`
  overflow: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  padding-bottom: 100px;
  margin-bottom: -100px;
`;

export const Block = styled.div`
  width: var(--block-width);
  display: inline-flex;
  justify-content: center;
  margin: 0 20px;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  background-color: ${theme.colors.white};
  position: relative;

  ${desktop} {
    margin: 0 55px;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const Spacer = styled.span`
  display: inline-block;
  width: calc((100vw - var(--block-width)) / 2);
`;

export const MobileBlockImage = styled.div`
  width: 100%;
  position: relative;
`;

export const TabletBlockImage = styled.div`
  width: 50%;
  position: relative;
`;

export const BlockIndicator = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: rgba(0, 74, 41, 0.7);
  border-radius: 60px;
  color: ${theme.colors.white};
  font-size: 16px;
  z-index: 10;
  padding: 5px 10px;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  cursor: default;
  user-select: none;
`;

export const MobileRow = styled.div`
  display: none;

  ${mobile} {
    display: block;
  }
`;

export const TabletRow = styled.div`
  display: none;

  ${tablet} {
    display: block;
  }
`;

export const DotsRow = styled.div`
  margin-top: 15px;
  text-align: center;
`;

interface DotProps {
  active: boolean;
}

export const Dot = styled.div<DotProps>`
  font-size: 0;
  display: inline-block;
  margin: 5px;
  width: 8px;
  height: 8px;
  background-color: ${theme.colors.darkGreen};
  border-radius: 100%;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
`;

export const PageRow = styled.div`
  position: absolute;
  pointer-events: none;
  width: calc(var(--block-width) - 40px);
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 20;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 1170px) {
    width: calc(var(--block-width) + 140px);
  }
`;

interface PageArrowProps {
  $disabled: boolean;
}

export const PageArrow = styled.button<PageArrowProps>`
  pointer-events: auto;
  background: ${theme.colors.white};
  border: 0;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.darkGreen};
  z-index: 10;
  cursor: pointer;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px ${theme.colors.darkGreen};
  }

  svg {
    transform: rotate(90deg);
  }

  &:last-child {
    svg {
      transform: rotate(-90deg);
    }
  }

  ${(props) => (props.$disabled ? `visibility: hidden;` : '')}
`;
